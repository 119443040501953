import React from "react"
import "@styles/components/navigation.scss"
import LoginLogo from "../assets/images/login.svg"
import { getCookie } from "./helpers/Cookies"
import Dropdown from "./Dropdown"

const Navigation = props => {
  const browser = typeof window !== "undefined" && window;

  const isUserLoggedIn = browser && getCookie('auth_id_token') && getCookie('auth_refresh_token');

  return (
    <nav className="navigation">
       <ul className="navigation__links">
        {props.navLinks.map((link, index) => {
            return (
              <Dropdown key={index} link={link} />
            )
          })}
        </ul>
        <div className="button-group-nav">
          {isUserLoggedIn ?
          <a
          className="button buttonv2-primary"
          href={process.env.GATSBY_MANAGE_BOX}
          >
            Manage Box
          </a>
          :
          <>
            <a
              className="button buttonv2-secondary"
              href={process.env.GATSBY_LOGIN}
            >
              <div><img className="login-logo" src={LoginLogo} alt="login" /></div>
              Log in
            </a>
            <a
              className="button buttonv2-primary"
              href={process.env.GATSBY_REGISTER}
            >
              Subscribe
            </a>
          </>
          }
          </div>
    </nav>
  )
}

export default Navigation
