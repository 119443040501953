import React from "react"

import AccordionSection from "@slices/AccordionSection"
import CarouselSection from "@slices/CarouselSection"
import CodeBlock from "@slices/CodeBlock"
import ColumnSection from "@slices/ColumnSection"
import CuratedPostsSection from "@slices/CuratedPostsSection"
import HeroCard from "@slices/HeroCard"
import ImageGallery from "@slices/ImageGallery"
import Ingredient from "@slices/Ingredient"
import IngredientGroup from "@slices/IngredientGroup"
import InstagramSection from "@slices/InstagramSection"
import NewsletterSection from "@slices/NewsletterSection"
import RichTextSection from "@slices/RichTextSection"
import TrustPilotSection from "@slices/TrustpilotSection"
import CTASection from "@slices/CTASection"
import CategoryNavigation from "@slices/CategoryNavigation"
import HeroPostcodeCheck from "@slices/HeroPostcodeCheck"
import Boxes from "@slices/Boxes"
import FeatureCard from "@slices/FeatureCard"
import CommunitySection from "@slices/CommunitySection"
import CuratedRecipeSection from "@slices/CuratedRecipeSection"
import CardSection from "@slices/CardSection"
import LeadGenSection from "@slices/LeadGenSection"
import BannerSection from "@slices/BannerSection"

const SliceZone = props => {
  const sliceComponents = {
    accordion_section: AccordionSection,
    body_text: RichTextSection,
    code_block_section: CodeBlock,
    column_section: ColumnSection,
    cta_section: CTASection,
    curated_posts_section: CuratedPostsSection,
    hero_section: HeroCard,
    image_gallery: ImageGallery,
    ingredient_group: IngredientGroup,
    ingredient: Ingredient,
    instagram_section: InstagramSection,
    newsletter_section: NewsletterSection,
    trustpilot_score_section: TrustPilotSection,
    category_navigation_: CategoryNavigation,
    carousel: CarouselSection,
    hero_postcode_check: HeroPostcodeCheck,
    boxes: Boxes,
    feature_card: FeatureCard,
    curated_recipe_section: CuratedRecipeSection,
    community_section: CommunitySection,
    card_section: CardSection,
    lead_gen_section: LeadGenSection,
    banner: BannerSection,
  }

  const sliceZoneContent = props.sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} {...props} />
    }
    return null
  })

  return <>{sliceZoneContent}</>
}

export default SliceZone
