import React from "react";
import "@styles/components/dropdown.scss";
import CustomLink from "./helpers/CustomLink";
import { ConditionalWrapper } from "./helpers/ConditionalWrapper";

const Dropdown = ({ link }) => {
  const hasSubLinks = Array.isArray(link.items) && link.items.length > 0;

  const renderSubLinks = () => (
    <>
      <div className="dropdown-content">
        <hr className="breakline" />
        <div className="row">
          {link.items[0].mega_nav_columns ? (
            link.items.map((item, index) => (
              <div className="column" key={index}>
                <div className="column-heading">
                  {item.mega_nav_columns?.document.data.title}
                </div>
                {item.mega_nav_columns?.document.data.links.map(
                  (subLink, subIndex) => (
                    <ConditionalWrapper
                      key={subIndex}
                      condition={subLink.link}
                      wrapper={(children) => (
                        <CustomLink
                          type={subLink.link?.type || ""}
                          url={subLink.link?.url}
                        >
                          {children}
                        </CustomLink>
                      )}
                    >
                      {subLink.label}
                    </ConditionalWrapper>
                  )
                )}
              </div>
            ))
          ) : (
            <div className="column">
              <div className="column-heading">{link.primary.label}</div>
              {link.items.map((item, index) => (
                <ConditionalWrapper
                  key={index}
                  condition={item.link}
                  wrapper={(children) => (
                    <CustomLink type={item.link?.type || ""} url={item.link?.url}>
                      {children}
                    </CustomLink>
                  )}
                >
                  {item.label}
                </ConditionalWrapper>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="overlay"></div>
    </>
  );

  return (
    <nav className="navbar">
      <div className="dropdown">
        <ConditionalWrapper
          condition={link.primary.link}
          wrapper={(children) => (
            <CustomLink
              className="dropbtn"
              type={link.primary.link?.type || ""}
              url={link.primary.link?.url}
            >
              {children}
            </CustomLink>
          )}
        >
          <div className={!link.primary.link ? "dropbtn" : null}>
            {link.primary.label}
          </div>
        </ConditionalWrapper>
        {hasSubLinks && renderSubLinks()}
      </div>
    </nav>
  );
};

export default Dropdown;
