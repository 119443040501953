import React, { useState } from "react"
import "@styles/components/mobile-navigation.scss"
import Close from "../assets/images/close.svg"
import LoginLogo from "../assets/images/login.svg"
import { getCookie } from "./helpers/Cookies"
import MobileDropdown from "./MobileDropdown"

const MobileNavigation = props => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const browser = typeof window !== "undefined" && window;

  const isUserLoggedIn = browser && getCookie('auth_id_token') && getCookie('auth_refresh_token');

  // add mobile value to slice data
  const navLinks = props.navLinks.map(slice => {
    slice.mobile = true
    return slice
  })

  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const renderAuthButtons = () => {
    if (isUserLoggedIn) {
      return (
        <a
          href={process.env.GATSBY_MANAGE_BOX}
          className="button buttonv2-primary mobile-navigation__subscribe full-width"
        >
          Manage Box
        </a>
      );
    }

    return (
      <>
        <a
          href={process.env.GATSBY_LOGIN}
          className="button buttonv2-secondary mobile-navigation__login"
        >
          <img className="login-logo" alt="login logo" src={LoginLogo} />
          Log in
        </a>
        <a
          href={process.env.GATSBY_REGISTER}
          className="button buttonv2-primary mobile-navigation__subscribe full-width"
        >
          Subscribe
        </a>
      </>
    );
  };

  return (
    <nav className="mobile-navigation" aria-label="Mobile Navigation Drawer">
      <button
        className="mobile-navigation__hamburger-icon"
        onClick={handleDrawer}
        aria-label="open"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
          <path d="M15.5 2.214h-14a.143.143 0 0 0-.143.143V3.5c0 .079.064.143.143.143h14a.143.143 0 0 0 .143-.143V2.357a.143.143 0 0 0-.143-.143Zm0 11.143h-14a.143.143 0 0 0-.143.143v1.143c0 .078.064.143.143.143h14a.143.143 0 0 0 .143-.143V13.5a.143.143 0 0 0-.143-.143Zm0-5.571h-14a.143.143 0 0 0-.143.143V9.07c0 .079.064.143.143.143h14a.143.143 0 0 0 .143-.143V7.93a.143.143 0 0 0-.143-.143Z" fill="#292929"/>
        </svg>     
      </button>
      <div
        className={`mobile-navigation__drawer${isDrawerOpen ? "--open" : ""}`}
      >
        <div>
        </div>
        <div className="mobile-navigation__wrapper">
            <div className="mobile-navigation__header">
              <div className="header__logo">
                <img className="header__logo-image" src="https://images.prismic.io/oddbox/Z1m1ppbqstJ98Vin_logo-1-.png?auto=format,compress" alt="Oddbox" style={{ width: "120px" }} />
              </div>
              <button
                className="mobile-navigation__close"
                onClick={handleDrawer}
                aria-label="close"
              >
                <img className="mobile-navigation_image-close" alt="close" src={Close} />
              </button>
          </div>
          <div className="mobile-navigation__links-footer">
          <div className="mobile-navigation__links">
            {navLinks.map((link, index) => {
              return (
                <MobileDropdown key={index} link={link} />
              )
            })}
            </div>
          <div className="mobile-navigation__footer">{renderAuthButtons()}</div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default MobileNavigation
