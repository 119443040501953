import React, { useRef, useState, useEffect } from "react"
import "@styles/components/form.scss"
import axios from "axios"
import { RichText } from "prismic-reactjs"

const LeadGenSection = props => {
    const {
        lead_gen_title,
        lead_gen_description,
        lead_gen_disclaimer,
        lead_gen_event_name,
        lead_gen_cta_text,
    } = props.slice.primary

    const emailField = useRef(null)

    const [isValid, setIsValid] = useState(false)
    const [email, setEmail] = useState("")
    const [showThankYouMessage, setShowThankYouMessage] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleEmailInput = e => {
        setEmail(e.target.value)
      }

    useEffect(() => {
    if (isValidEmail(email)) {
        setIsValid(true)
    } else {
        setIsValid(false)
    }
    }, [email])

    const createProfileInBraze = async email => {
        const body = {
          event_name: lead_gen_event_name,
          email: email,
          email_subscribe: "opted_in",
          email_subscription_groups: {
            general_marketing: true,
            weekly_newsletter: true,
            surveys: true,
        },
        }

        const config = {
            headers: {
                "X-API-KEY": process.env.GATSBY_REGISTRATION_X_TOKEN,
            },
        }
    
        const trackAliasUrl = `${process.env.GATSBY_REGISTRATION_API}/registration/api/v1/customer/track-alias`

        const setToDataLayer = () => {
          function pushToDataLayer(obj) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(obj)
          }
          pushToDataLayer({event: 'lead-signup', name: lead_gen_event_name})
        }
      
        try {
            const response = await axios.post(trackAliasUrl, body, config)
            if (response.status === 201) {
              setShowThankYouMessage(true)
              setShowErrorMessage(false)
              setIsLoading(false)
              setToDataLayer()
            } else {
              console.log(response)
              setShowThankYouMessage(false)
              setShowErrorMessage(true)
              setIsLoading(false)
            }
          } catch (error) {
            console.error(error)
          }
        }
      
        const isValidEmail = email => {
          return /^.+@.+\..+$/.test(email)
        }
      
        const handleNewsletterSignup = () => {
          setIsLoading(true)
          createProfileInBraze(email)
        }
        
        return (
            <section className="container">
                <div className="newsletter is-centered">
                  <RichText
                    render={lead_gen_title.richText}                  
                  />
                  {!showThankYouMessage && (
                    <div>
                      <RichText
                        render={lead_gen_description.richText}
                      />
                      <div className="form">
                        <input
                          ref={emailField}
                          onChange={handleEmailInput}
                          type="text"
                          name=""
                          id=""
                          value={email}
                          placeholder="Email address"
                          required
                        />
                        <RichText 
                          render={lead_gen_disclaimer.richText}
                        />
                        <button
                          style={{ margin: "0 auto" }}
                          onClick={handleNewsletterSignup}
                          className="button button-primary"
                          value="signup"
                          disabled={isValid ? false : "disabled"}
                          id="lead-signup-btn"
                        >
                          {isLoading && <div className="loader"></div>}
                          {!isLoading && <>{lead_gen_cta_text}</>}
                        </button>
                      </div>
                    </div>
                  )}
                  {showThankYouMessage && (
                    <div>
                      <p className="underline">
                        Thank you for signing up to our newsletter!
                      </p>
                    </div>
                  )}
                  {showErrorMessage && (
                    <div>
                      <p className="underline">
                        Please ensure you have entered your Email address.
                      </p>
                    </div>
                  )}
                </div>
            </section>
    )
}

export default LeadGenSection
