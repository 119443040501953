import React, { useState } from "react";
import RightArrow from "../assets/images/right-arrow.svg";
import LeftArrow from "../assets/images/left-arrow.svg";
import CustomLink from "./helpers/CustomLink";
import "@styles/components/dropdown.scss";
import { ConditionalWrapper } from "./helpers/ConditionalWrapper";

const MobileDropdown = ({ link }) => {
  const [selectedLevel1Link, setSelectedLevel1Link] = useState(null);
  const [selectedLevel2Link, setSelectedLevel2Link] = useState(null);
  const [openLevel, setOpenLevel] = useState(0);

  const openLevel1 = (link) => {
    setSelectedLevel1Link(link);
    setOpenLevel(1);
  };

  const openLevel2 = (section) => {
    setSelectedLevel2Link(section);
    setOpenLevel(2);
  };

  const closeLevel = () => {
    setOpenLevel((prevLevel) => prevLevel - 1);
  };

  const { primary, items } = link;
  const hasSubLinks = items.length > 0;

  const renderLevel1 = () => (
    <button className="dropbtn" onClick={hasSubLinks ? () => openLevel1(link) : null}>
      <ConditionalWrapper
        condition={primary.link?.url}
        wrapper={(children) => (
          <CustomLink type={primary.link?.type || ""} url={primary.link?.url}>
            {children}
          </CustomLink>
        )}
      >
        <div>{primary.label}</div>
      </ConditionalWrapper>
      {hasSubLinks && <img src={RightArrow} alt="right arrow" />}
    </button>
  );

  const renderLevel2 = () => (
    <div className="mobile-menu">
      <button className="dropbtn sublink-title" onClick={closeLevel}>
        <img src={LeftArrow} alt="left arrow" />
        {selectedLevel1Link.primary.label}
      </button>
      {selectedLevel1Link.items?.map((subsection, index) =>
        subsection.mega_nav_columns ? (
          <button
            key={`level2-${index}`}
            className="dropbtn"
            onClick={() => openLevel2(subsection)}
          >
            {subsection.mega_nav_columns?.document.data.title}
            <img src={RightArrow} alt="right arrow" />
          </button>
        ) : (
          <div className="dropbtn" key={`level2-${index}`}>
            <ConditionalWrapper
              condition={subsection.link?.url}
              wrapper={(children) => (
                <CustomLink type={subsection.link?.type || ""} url={subsection.link?.url}>
                  {children}
                </CustomLink>
              )}
            >
              <div>{subsection.label}</div>
            </ConditionalWrapper>
          </div>
        )
      )}
    </div>
  );

  const renderLevel3 = () => (
    <div className="mobile-menu_2">
      <button className="dropbtn sublink-title" onClick={closeLevel}>
        <img src={LeftArrow} alt="left arrow" />
        {selectedLevel2Link.mega_nav_columns.document.data.title}
      </button>
      <div className="dropbtn__sublinks">
        {selectedLevel2Link.mega_nav_columns.document.data.links.map((item, index) => (
          <ConditionalWrapper
            key={`level3-${index}`}
            condition={item.link?.url}
            wrapper={(children) => (
              <CustomLink type={item.link?.type || ""} url={item.link?.url} className="menu-link">
                {children}
              </CustomLink>
            )}
          >
            {item.label}
          </ConditionalWrapper>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      {openLevel === 0 && renderLevel1()}
      {openLevel === 1 && selectedLevel1Link && renderLevel2()}
      {openLevel === 2 && selectedLevel2Link && renderLevel3()}
    </div>
  );
};

export default MobileDropdown;
