import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Navigation from "@components/Navigation"
import MobileNavigation from "@components/MobileNavigation"
import "@styles/components/header.scss"
import { useScreenMediaQuery } from "../hooks/useMediaQuery.hook"
import CustomLink from "./helpers/CustomLink"


const Header = () => {
  const { isDownLG } = useScreenMediaQuery()
  const [isHydrated, setIsHydrated] = useState(false);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  const data = useStaticQuery(graphql`
    {
      prismicMainNavigation(uid: {}) {
    data {
      body {
        ... on PrismicMainNavigationDataBodyLevel1Link {
          id
          slice_type
          items {
            mega_nav_columns {
              document {
                ... on PrismicMegaNavColumns {
                  id
                  data {
                    title
                    links {
                      label
                      link {
                        url
                        type
                      }
                    }
                  }
                }
              }
            }
            label
            link {
              url
              type
            }
          }
          primary {
            label
            link {
              url
              type
            }
          }
        }
      }
    }
  }
    }
    `)

    const navLinks = data.prismicMainNavigation.data.body;

  return (
    <header className="header">
      <div className="header__nav">
        <div className="header_nav_group">
          {isDownLG && isHydrated && <><div><a className="button buttonv2-primary small-btn" href={process.env.GATSBY_REGISTER}>
            Subscribe
          </a></div><MobileNavigation navLinks={navLinks} /></>
          }
        </div>
        <div className="logo-links">
        <CustomLink url="/" type="home" className="header__logo">
            <img className="header__logo-image" src="https://images.prismic.io/oddbox/Z1m1ppbqstJ98Vin_logo-1-.png?auto=format,compress" alt="Oddbox" style={{ maxWidth: '120px', height: "37px" }} />
        </CustomLink>
        <Navigation navLinks={navLinks} />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
